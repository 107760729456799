import { IDataItem, Utils } from '@wix/promotions-types';
import { HttpClient } from '@wix/yoshi-flow-editor';

interface UpdateCampaignI {
  instance: string;
  campaignId: string;
  campaign: IDataItem;
  context?: string;
}

export const updateCampaign = async ({
  instance,
  campaignId,
  campaign,
  context,
}: UpdateCampaignI) => {
  const httpClient = new HttpClient();
  Utils.DataItemApi(instance, httpClient)
    .updateDataItem(campaignId, campaign)
    .then((campaign) => {
      return campaign;
    })
    .catch((e) => console.log('updateCampaign fail', context, { e, campaign }));
};
