import { WidgetIds } from '@wix/promotions-types';
import { presets as mainPresets } from '../utils';


export const WIDGETS = {
  ANNOUNCEMENT: {
    widgetId: WidgetIds.ANNOUNCEMENTS,
    desktop: mainPresets().ANNOUNCEMENTS[0].id,
    mobile: mainPresets().ANNOUNCEMENTS[0].connectedMobilePresetID,
  },
  SUBSCRIBE: {
    widgetId: WidgetIds.SUBSCRIBE,
    desktop: mainPresets().SUBSCRIBE[0].id,
    mobile: mainPresets().SUBSCRIBE[0].connectedMobilePresetID,
  },
};
