import { Utils, ICouponOrDiscount, WixLocales } from '@wix/promotions-types';
import { getViewerEssentials } from '../viewer-utils';

interface IReplaceDynamicValues {
  textValue: string;
  endDate?: Date | undefined;
  locale?: WixLocales;
  discount?: ICouponOrDiscount;
  isMandatory: boolean;
}

export const replaceDynamicValues = ({
  textValue,
  endDate,
  locale = 'en',
  discount,
  isMandatory,
}: IReplaceDynamicValues) => {
  if (!textValue && !isMandatory) {
    return '';
  }
  const { t } = getViewerEssentials();
  let isProduct, name, category_name, product_name;
  const isCoupon = discount?.isCoupon;
  if (isCoupon) {
    isProduct = isCoupon && discount?.couponMetadata?.isProduct;
    name = discount?.couponMetadata?.name || '';
    category_name = !isProduct ? name : '';
    product_name = isProduct ? name : '';
  }

  const end_date = endDate?.getTime()
    ? t(
        'promotions.widget.text_date_from_ms',
        Utils.getDateFromMsWithLocale(locale, endDate.getTime()),
      )
    : '';

  const discount_type = isCoupon
    ? Utils.getDiscountDescription(discount, t, false)
    : discount?.offer || '';
  const dynamicValues = {
    discount_type,
    end_date,
    category_name,
    product_name,
  };

  let replacedText = textValue;
  for (const [key, value] of Object.entries(dynamicValues)) {
    replacedText = replacedText.replace(
      new RegExp(`\\{\\{${key}\\}\\}`, 'g'),
      Utils.formatDynamicValue(value),
    );
  }

  return replacedText;
};
