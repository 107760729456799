export enum SubscribePresetNames {
  overlay = 'Overlay',
  centerOpacity = 'Center Opacity',
  sideBox = 'Side Box',
  noImage = 'No Image',
  sideBySide = 'Side by side',
  midStrip = 'Mid strip',
  smallStrip = 'Small strip',
  mobileOverlay = 'Mobile Overlay',
}

export enum SubscribePresetIds {
  overlay = 'variants-lm69mal51',
  centerOpacity = 'variants-lm69mal71',
  sideBox = 'variants-lm69mal72',
  noImage = 'variants-lm69mal73',
  sideBySide = 'variants-lm69mal7',
  midStrip = 'variants-lm69mal6',
  smallStrip = 'variants-lm69mal81',
}

export enum SubscribeMobilePresetIds {
  overlay = 'variants-lm69mal74',
  centerOpacity = 'variants-lm69mal75',
  sideBox = 'variants-lm69mal76',
  noImage = 'variants-lm69mal77',
  sideBySide = 'variants-lm69mal78',
  midStrip = 'variants-lm69mal8',
  smallStrip = 'variants-lm69mal8', // Same for mid & small strip
}

export enum AnnouncementPresetNames {
  overlay = 'Overlay',
  centerOpacity = 'Center Opacity',
  sideBox = 'Side Box',
  noImage = 'No Image',
  sideBySide = 'Side by side',
  midStrip = 'Mid strip',
  smallStrip = 'Small strip',
  mobileOverlay = 'Mobile Overlay',
}

export enum AnnouncementsPresetIds {
  overlay = 'variants-ljmnpca91',
  centerOpacity = 'variants-lkf7jnem',
  sideBox = 'variants-lkf84v3s',
  noImage = 'variants-lkf8z0aa',
  sideBySide = 'variants-lkb7azwr',
  midStrip = 'variants-ljr4o464',
  smallStrip = 'variants-llf52y88',
}

export enum AnnouncementsMobilePresetIds {
  overlay = 'variants-lks12zn1',
  centerOpacity = 'variants-lks7u0tp',
  sideBox = 'variants-lks87upc',
  noImage = 'variants-lks8pipa',
  sideBySide = 'variants-lksb9ybs',
  midStrip = 'variants-lkse67d5',
  smallStrip = 'variants-lkthpeje',
}
