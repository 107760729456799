import { WidgetDesignManifestBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetDesign/widgetDesignManifestBuilder';
import { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import { AnnouncementRoles } from '../enums';

const tabs = [
  {
    labelKey: 'widget.global_design.background',
    ids: [AnnouncementRoles.Widget],
  },

];

export default function globalDesignBuilder(
  widgetDesignBuilder: WriteOnlyBuilder<WidgetDesignManifestBuilder>,
  t,
) {
  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

  tabs.forEach(({ labelKey, ids }) =>
    widgetDesignTabsBuilder.addTab((tabBuilder) =>
      tabBuilder
        .set({
          label: t(labelKey),
        })
        .groups()
        .set({
          roles: ids,
        }),
    ),
  );
}
