import { CampaignType } from '@wix/promotions-types';
import { AnnouncementsPresetIds } from '../../enums';

const presetsWithoutElements: Record<CampaignType, any> = {
  announcements_type: {
    presetsWithoutImage: [
      AnnouncementsPresetIds.sideBySide,
      AnnouncementsPresetIds.smallStrip,
      AnnouncementsPresetIds.midStrip,
      AnnouncementsPresetIds.noImage,
    ],
    presetsWithLine: [AnnouncementsPresetIds.smallStrip],
  },
  get_subscribers_type: {
    presetsWithoutImage: [],
    presetsWithLine: [],
  },
  limited_offer_type: {
    presetsWithoutImage: [],
    presetsWithLine: [],
  },
};

export const getPresetsWithElementsToRemove = (campaignType: CampaignType) => {
  return presetsWithoutElements[campaignType];
}
