import { OfferingType } from '@wix/promotions-types';
import { BiConstants } from '.';

export type ButtonsTypeBi =
  | ManagePromosPanel
  | GfppBtns
  | ElementsPanelBtns
  | WidgetLayoutBtns
  | WidgetDesignBtns
  | StretchBtns;

export type GfppBtns = (typeof BiConstants.gfppBtns)[number];
export type StretchBtns = (typeof BiConstants.stretchBtns)[number];
export type ManagePromosPanel =
  (typeof BiConstants.managePromosPanelBtns)[number];
export type WidgetDesignBtns = (typeof BiConstants.widgetDesignBtns)[number];
export type WidgetLayoutBtns = (typeof BiConstants.widgetLayoutBtns)[number];
export type ElementsPanelBtns = (typeof BiConstants.elementsPanelBtns)[number];

export type ParentViewType = GfppBtns | 'editor';

export type ButtonClickParams = {
  entity?: string | null;
  entity_id?: string | null;
};

export type PromoPublishedParams = {
  promo_id: string;
  preset_id: string | null;
  widget_elements: string;
  widget_layout?: string;
  stretch?: boolean;
  pause_between_images?: number;
};

export type PromoUOUParams = {
  promo_id: string;
  template_type: TemplateType;
  promo_type: OfferingType;
  cta_id: string;
};

export enum TemplateType {
  announcement = 'announcement',
}
