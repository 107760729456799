import { IDataItem } from '@wix/promotions-types';
import { TemplateType, bi } from '../../bi';
import { getViewerEssentials } from './getViewerEssentials';

export const sendBiCtaClick = (campaign: IDataItem) => {
  const { flowAPI } = getViewerEssentials();
  const biLogger = bi({ flowAPI });
  campaign._id &&
    biLogger.sitePromoUouCtaClick({
      promo_id: campaign._id,
      template_type: TemplateType.announcement,
      promo_type: campaign.offeringType,
      cta_id: biLogger.getCtaId(campaign),
    });
};
