import { CampaignType } from '@wix/promotions-types';

export enum AnnouncementRoles {
  Widget = 'box1',
  MultiStateBox = 'multiStateBox2',
  Loading = 'Loading',
  Default = 'Default',
  BrokenState = 'Expired',
  CtaMultiStateBox = 'multiStateBox1',
  CouponCodeState = 'CouponCode',
  CouponCodeStateSuccess = 'CouponCodeSuc',
  LinkState = 'AutoDis',
  CouponCodeBtn = 'button2',
  CouponCodeSucBtn = 'button3',
  LinkBtn = 'button1',
  Heading = 'heading',
  Title = 'title',
  Subtitle = 'subtitle',
  Disclaimer = 'disclaimer',
  Image = 'imageX1',
  Spinner = 'vectorImage1',
  CampaignPanelDD = 'panelDropdown1',
  CampaignPanelName = 'panelTextInput1',
  EmptyStatePanelBtn = 'panelButton1',
  EmptyStatePanelText = 'panelEmptyState1',
  EmptyStateExpPanelBtn2 = 'panelButton2',
  BrokenStateTitle = 'text1',
  BrokenStateSubtitle = 'text2',
  smallStripLine = 'line1',
}

export enum SubscribeRoles {
  Widget = 'box1',
  MultiStateBox = 'multiStateBox2',
  ThankYouState = 'ThankYou',
  SubscribeState = 'Subscribe',
  Loading = 'Loading',
  Default = 'Default',
  BrokenState = 'Expired',
  CtaMultiStateBox = 'multiStateBox3',
  CouponCodeBtn = 'CouponCode',
  LinkState = 'AutoDis',
  LinkBtn = 'button3',
  CtaButton = 'CTA',
  Input = 'input2',
  Title = 'Title1',
  Subtitle = 'Subtitle1',
  Disclaimer = 'Disclaimer1',
  Title2 = 'Title2',
  Subtitle2 = 'Subtitle2',
  Disclaimer2 = 'Disclaimer2',
  Image = 'imageX1',
  Spinner = 'vectorImage1',
  CampaignPanelDD = 'panelDropdown1',
  EmptyStatePanelBtn = 'panelButton1',
  EmptyStatePanelText = 'panelEmptyState1',
  EmptyStateExpPanelBtn2 = 'panelButton2',
  BrokenStateTitle = 'text1',
  BrokenStateSubtitle = 'text2',
  smallStripLine = 'line1',
  ErrInputMessage = 'box13',
}

export const rolesFromType: Record<CampaignType, any> = {
  announcements_type: AnnouncementRoles,
  get_subscribers_type: SubscribeRoles,
  limited_offer_type: AnnouncementRoles,
};
