import { getDataToRender } from '../../viewer-utils/Main';
import model from './model';

const campaignType = 'get_subscribers_type';

export default model.createController(
  ({ $bind, $widget, flowAPI, controllerConfig }) => {
    $widget.onPropsChanged(async (prevProps, newProps) => {
      const { campaignId } = newProps;
      const prevCampaignId = prevProps.campaignId;
      if (prevCampaignId !== campaignId) {
        getDataToRender(controllerConfig.$w, flowAPI, campaignType, $widget);
      }
    });

    return {
      pageReady: async ($w) =>
        getDataToRender($w, flowAPI, campaignType, $widget),
      exports: {},
    };
  },
);
