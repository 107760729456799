import { ICampaignDataItem, OfferingType } from '@wix/promotions-types';
import { getCamapaignOfferingType } from './getCamapaignOfferingType';
import { getViewerEssentials } from './getViewerEssentials';
import { selectorFactory } from './selectorFactory';

interface CreateCtaBtnI {
  campaign: ICampaignDataItem;
  $w: any;
}

export const createCtaBtn = async ({ campaign, $w }: CreateCtaBtnI) => {
  const { flowAPI } = getViewerEssentials();

  const offeringType = getCamapaignOfferingType(campaign);
  const { campaignType } = campaign?.data;


  const {
    showCodeButton,
    showLinkButton,
    onClickCta,
    collapseBtn,
    setInputListener,
    setCampaign,
  } = selectorFactory($w, campaignType);
  const { baseUrl } = flowAPI.controllerConfig.wixCodeApi.location;

  const selectedPage =
    campaign?.data?.offeringItemUrl || campaign?.data?.offeringPageUrl;

  const offeringCta: Record<OfferingType, string> = {
    // @ts-expect-error
    coupon: campaign?.data?.offeringData?.code,
    site_page: selectedPage,
    automatic_discount: selectedPage,
    membership: 'membership',
    // @ts-expect-error
    membership_coupon: campaign?.data?.offeringData?.code,
  };

  const ctaProperty = offeringCta[offeringType as OfferingType];
  const isCoupon = offeringType?.includes('coupon');
  const ctaData = isCoupon ? ctaProperty : baseUrl + ctaProperty;
  setCampaign(campaign.data);
  if (!ctaProperty) {
    return collapseBtn();
  }

  if (campaignType === 'announcements_type') {
    isCoupon ? await showCodeButton() : await showLinkButton();
  } else if (campaignType === 'get_subscribers_type') {
    console.log('Cta Data', ctaData);
    setInputListener(ctaData);
  }
  onClickCta(ctaData, isCoupon);
};
