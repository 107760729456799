import { sendBiCtaClick } from './biActions';
import { getViewerEssentials } from './getViewerEssentials';
import { selectorFactory } from './selectorFactory';

const copyToClipboard = (couponButton, $w, campaign) => {

  const { flowAPI, campaignType } = getViewerEssentials();
  const { copyToClipboard } = flowAPI.controllerConfig.wixCodeApi.window;
  const { showCodeSuccess, showCodeButton } = selectorFactory($w, campaignType);
  const text = couponButton.label;
  const icon = couponButton.icon;
  copyToClipboard(text)
    .then(() => {
      showCodeSuccess();
      sendBiCtaClick(campaign);
      setTimeout(() => {
       showCodeButton(icon);
      }, 5000);
    })
    .catch((err) => {
      console.log('Copy to clipboard error', err);
    });
};

export const createCouponButton = (
  buttonId: string,
  data: string,
  $w,
  campaign,
) => {
  const couponBtn = $w(`#${buttonId}`);
  couponBtn.label = data;
  couponBtn.onClick(() => copyToClipboard(couponBtn, $w, campaign));
};
