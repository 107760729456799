import {
  generateDemoWidget,
  getEditorWidgetData,
  getViewerWidgetData,
  selectorFactory,
  setViewerEssentials,
} from '../helpers';

export async function getDataToRender($w, flowAPI, campaignType, $widget) {
  const { t } = flowAPI.translations;
  const { showLoader, collapseWidget } = selectorFactory($w, campaignType);
  setViewerEssentials({ flowAPI, t, campaignType });
  const { isViewer, isPreview } = flowAPI.environment;
  const isViewMode = isPreview || isViewer;
  if (!isViewMode) {
    await showLoader();
  }
  const onFailAction = async (errText?: string) => {
    isViewMode
      ? await collapseWidget()
      : await generateDemoWidget(campaignType, $w);

    if (errText) {
      console.log(`onFail ${errText}`);
    }
  };

  const getWidgetData = isViewMode ? getViewerWidgetData : getEditorWidgetData;

  await getWidgetData(campaignType, $w, onFailAction, $widget, flowAPI);
}
