import {
  CampaignStatusType,
  CampaignType,
  IDataItem,
} from '@wix/promotions-types';
import { rolesFromType,CouponCodeIcons } from '../../enums';
import { sendBiCtaClick } from './biActions';
import { createCouponButton } from './createCouponButton';
import {
  isHTMLStringEmpty,
  addUnderlineToLink,
  validateEmail,
} from './factoryHelpers';

type MethodType = 'text' | 'html' | 'label' | 'richText';

export const selectorFactory = ($w, campaignType: CampaignType) => {
  let campaign;
  const elementRoles = rolesFromType[campaignType];
  const collapseWidget = () => $w(`#${elementRoles.Widget}`).collapse();

  const collapseBtn = () => $w(`#${elementRoles.CtaMultiStateBox}`).collapse();

  const showLoader = async () =>
    $w(`#${elementRoles.MultiStateBox}`).changeState(elementRoles.Loading);

  const showWidget = () => {
    return $w(`#${elementRoles.MultiStateBox}`).changeState(
      elementRoles.Default,
    );
  };

  const showBrokenWidget = (promoName, t, status: CampaignStatusType) => {
    changeText(
      elementRoles.BrokenStateTitle,
      t(`promotions.widget.${status}.title`),
    );
    changeText(
      elementRoles.BrokenStateSubtitle,
      t(`promotions.widget.${status}.subtitle`, { promoName }),
    );
    return $w(`#${elementRoles.MultiStateBox}`).changeState(
      elementRoles.BrokenState,
    );
  };
  const setCampaign = (data: IDataItem) => (campaign = data);

  const showCodeButton = (
    icon?:string
  ) => {
    if (campaignType === 'announcements_type') {
      $w(`#${elementRoles.CtaMultiStateBox}`).changeState(
        elementRoles.CouponCodeState,
      );

    if(icon)
      $w(`#${elementRoles.CouponCodeBtn}`).icon = icon;
    }
    // else if (campaignType === 'get_subscribers_type') {
    //   $w(`#${elementRoles.CtaMultiStateBox}`).changeState(
    //     elementRoles.CouponCodeState
    //   );
    // }
  };

  const showCodeSuccess = () => {
   $w(`#${elementRoles.CouponCodeBtn}`).icon = CouponCodeIcons.checkMarkIcon;
  };

  const showLinkButton = () =>
    $w(`#${elementRoles.CtaMultiStateBox}`).changeState(elementRoles.LinkState);

  const onClickCta = (data: string, isCoupon: boolean = false) => {
    if (campaignType === 'announcements_type') {
      const btn = isCoupon ? elementRoles.CouponCodeBtn : elementRoles.LinkBtn;
      isCoupon
        ? createCouponButton(btn, data, $w, campaign)
        : openTab(btn, data);
    } else if (campaignType === 'get_subscribers_type') {
      $w(`#${elementRoles.CtaButton}`).onClick(() => onSubscribeClick(data));
    }
  };

  const onSubscribeClick = (data: string) => {
    const inputElement = $w(`#${elementRoles.Input}`);
    const inputValue = inputElement.value;
    const isValidEmail = validateEmail(inputValue);
    if (isValidEmail) {
      changeState(elementRoles.CtaMultiStateBox, elementRoles.ThankYouState);
      createCouponButton(elementRoles.CouponCodeBtn, data, $w, campaign);
    } else {
      showElement(elementRoles.ErrInputMessage);
      setTimeout(() => {
        collapseElement(elementRoles.ErrInputMessage);
      }, 5000);
    }
  };

  const showElement = (selector: string) => {
    $w(`#${selector}`).show();
  };

  const collapseElement = (selector: string) => $w(`#${selector}`).collapse();

  const changeState = (containerId: string, targetId: string) => {
    $w(`#${containerId}`).changeState(targetId);
  };

  const openTab = (id: string, url: string) => {
    $w(`#${id}`).link = url;
    $w(`#${id}`).target = '_self';
    $w(`#${id}`).onClick(() => campaign && sendBiCtaClick(campaign));
  };

  const changeText = async (
    selector: string,
    value: string,
    method: MethodType = 'text',
  ) => {
    if (!value) {
      return $w(`#${selector}`).collapse();
    }
    $w(`#${selector}`)[method] = value;
  };

  const changeToDemoContent = (elementIds: any) => {
    for (const { id, text, role } of elementIds) {
      switch (role) {
        case 'btn':
          changeText(id, text, 'label');
          break;
        default:
          changeText(id, text, 'text');
      }
    }
    showLinkButton();
  };

  const formatDisclaimer = (
    value,
    type: CampaignType = 'announcements_type',
  ) => {
    value = isHTMLStringEmpty(value) ? '' : addUnderlineToLink(value);
    changeText(elementRoles.Disclaimer, value, 'richText');
    if (type === 'get_subscribers_type') {
      // Manipulate disc second state
      changeText(elementRoles.Disclaimer2, value, 'richText');
    }
  };

  const setInputListener = (data: string) => {
    console.log('settingInput');
    $w(`#${elementRoles.Input}`).onKeyPress(({ key }) => {
      if (key === 'Enter') {
        onSubscribeClick(data);
      }
    });
  };

  return {
    collapseWidget,
    showLoader,
    showWidget,
    showCodeButton,
    showCodeSuccess,
    showLinkButton,
    changeText,
    changeToDemoContent,
    onClickCta,
    showBrokenWidget,
    collapseBtn,
    formatDisclaimer,
    setInputListener,
    showElement,
    collapseElement,
    setCampaign,
  };
};
