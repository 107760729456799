export enum MultistateBoxIds {
  mainState = 'comp-lismc2wh_r_comp-li0d9g7u',
  successState = 'comp-lismc2wh_r_comp-li1pl7hj',
  announcements = 'comp-lkax5poh_r_comp-li0d9g7o',
}

export enum SubscribeMultistateIds {
  mainState = 'comp-lomp6228_r_comp-loms86xq',
  successState = 'comp-lomp6228_r_comp-lomt1o4o',
}
