import { Utils } from '@wix/promotions-types';
import { HttpClient } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from '../editorSDKUtils';

export const getCampaigns = async ({
  editorSDK,
  instance,
  shouldUpdate,
}: {
  editorSDK?: any;
  instance?: string;
  shouldUpdate?: boolean;
}) => {
  shouldUpdate = shouldUpdate ? shouldUpdate : false;
  const httpClient = new HttpClient();
  if (editorSDK) {
    const editorSDKUtils = new EditorSDKUtils(editorSDK);
    instance = await editorSDKUtils.getInstance();
  }
  if (!instance) {
    return;
  }

  const campaigns = await Utils.DataItemApi(
    instance,
    httpClient,
  ).queryDataItems({ shouldUpdate });

  return campaigns;
};
