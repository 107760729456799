import { CampaignType } from '@wix/promotions-types';
import { AnnouncementRoles, SubscribeRoles } from '../enums';

export interface WidgetElement {
  id: AnnouncementRoles | SubscribeRoles;
  text: string;
  role?: string;
}

export const widgetDemoElements: Record<CampaignType, WidgetElement[]> = {
  announcements_type: [
    {
      id: AnnouncementRoles.Disclaimer,
      text: 'promotions.widget.announcement.demo.disclaimer',
    },
    {
      id: AnnouncementRoles.Heading,
      text: 'promotions.widget.announcement.demo.heading',
    },
    {
      id: AnnouncementRoles.Title,
      text: 'promotions.widget.announcement.demo.title',
    },
    {
      id: AnnouncementRoles.Subtitle,
      text: 'promotions.widget.announcement.demo.subtitle',
    },
    {
      id: AnnouncementRoles.LinkBtn,
      text: 'promotions.widget.demo.linkBtn',
      role: 'btn',
    },
    {
      id: AnnouncementRoles.CouponCodeBtn,
      text: 'promotions.widget.announcement.demo.couponBtn',
      role: 'btn',
    },
  ],
  get_subscribers_type: [
    {
      id: SubscribeRoles.Title,
      text: 'promotions.widget.subscribe.demo.title',
    },
    {
      id: SubscribeRoles.Subtitle,
      text: 'promotions.widget.subscribe.demo.subtitle',
    },
    {
      id: SubscribeRoles.Disclaimer,
      text: 'promotions.widget.subscribe.demo.disclaimer',
    },
    {
      id: SubscribeRoles.CtaButton,
      text: 'promotions.widget.subscribe.demo.subscribeCta',
      role: 'btn',
    },
    {
      id: SubscribeRoles.Title2,
      text: 'promotions.widget.subscribe.demo.title.successState',
    },
    {
      id: SubscribeRoles.Subtitle2,
      text: 'promotions.widget.subscribe.demo.subtitle.successState',
    },
    {
      id: SubscribeRoles.Disclaimer2,
      text: 'promotions.widget.subscribe.demo.disclaimer.successState',
    },
    {
      id: SubscribeRoles.LinkBtn,
      text: 'promotions.widget.subscribe.demo.linkBtn',
      role: 'btn',
    },
    {
      id: SubscribeRoles.CouponCodeBtn,
      text: 'promotions.widget.subscribe.demo.couponBtn.successState',
      role: 'btn',
    },
  ],
  limited_offer_type: [],
};
