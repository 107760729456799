import { CampaignType } from '@wix/promotions-types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  selectorFactory,
  getViewerWidgetData,
  generateDemoWidget,
  setViewerEssentials,
  getEditorWidgetData,
} from '..';

export const onViewerPageReady = async ({
  $widget,
  flowAPI,
  $w,
  campaignType,
}: {
  $widget: any;
  flowAPI: PlatformControllerFlowAPI;
  $w: any;
  campaignType: CampaignType;
}): Promise<void> => {
  const { t } = flowAPI.translations;
  const { showLoader, collapseWidget } = selectorFactory($w, campaignType);
  setViewerEssentials({ flowAPI, t, campaignType });
  const { campaignId, compId } = $widget.props;
  const { isViewer, isPreview } = flowAPI.environment;
  const isViewMode = isPreview || isViewer;

  console.log('onViewerPageReady', {
    flowAPI,
    campaignId,
    compId,
  });

  await getDataToRender();

  $widget.onPropsChanged(async (prevProps, newProps) => {
    const { campaignId } = newProps;
    const prevCampaignId = prevProps.campaignId;
    if (prevCampaignId !== campaignId) {
      getDataToRender();
    }
  });

  async function getDataToRender() {
    if (!isViewMode) {
      await showLoader();
    }
    const onFailAction = async (errText?: string) => {
      isViewMode
        ? await collapseWidget()
        : await generateDemoWidget(campaignType, $w);

      if (errText) {
        console.log(`onFail ${errText}`);
      }
    };

    const getWidgetData = isViewMode
      ? getViewerWidgetData
      : getEditorWidgetData;

    await getWidgetData(campaignType, $w, onFailAction, $widget, flowAPI);
  }
};
