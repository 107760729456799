import { CampaignType } from '@wix/promotions-types';
import { widgetDemoElements, WidgetElement } from '../../constants';
import { getViewerEssentials } from './getViewerEssentials';
import { selectorFactory } from './selectorFactory';

export const generateDemoWidget = async (
  campaignType: CampaignType,
  $w: any,
) => {
  const { t } = getViewerEssentials();
  const { changeToDemoContent, showWidget } = selectorFactory($w, campaignType);
  const elementIds = widgetDemoElements[campaignType].map(
    (el: WidgetElement): WidgetElement => ({
      ...el,
      text: t(el.text),
    }),
  );
  changeToDemoContent(elementIds);
  await showWidget();
};
