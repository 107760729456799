import { Bi, IDataItem } from '@wix/promotions-types';
import {
  EditorScriptFlowAPI,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import {
  ButtonClickParams,
  ButtonsTypeBi,
  ParentViewType,
  PromoPublishedParams,
  PromoUOUParams,
} from './biTypes';
import { BiConstants } from '.';

export interface BiBaseParams {
  flowAPI: EditorScriptFlowAPI | PlatformControllerFlowAPI;
  msid?: string;
  instance_id?: string;
}

export const bi = ({ flowAPI, msid, instance_id }: BiBaseParams) => {
  const isEditorSDK = msid && instance_id;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  const baseParams = {
    msid: '',
    instance_id: '',
  };

  if (isEditorSDK) {
    baseParams.msid = msid;
    baseParams.instance_id = instance_id;
  }

  return {
    sitePromoButtonClick(
      button_name: ButtonsTypeBi,
      buttonClickParams?: ButtonClickParams,
    ) {
      const biFunction = Bi.getBiEventFunction('buttonClick');
      const parent_view = getParentView(button_name);

      const biParams = {
        button_name,
        parent_view,
        ...buttonClickParams,
        ...(isEditorSDK ? baseParams : {}),
      };
      console.log('[BI buttonClick] : ', { biParams });
      return biLogger.report(biFunction(biParams));
    },
    sitePromoPublished(params: PromoPublishedParams) {
      const biFunction = Bi.getBiEventFunction('promoPublished');
      console.log('>>>> sitePromoPublished', { params });

      return biLogger.report(
        biFunction({
          ...(isEditorSDK ? baseParams : {}),
          ...params,
          // promo_id: 'promo_id',
          // preset_id: 'preset_id',
          // widget_elements:
          //   '{image: true/false, heading: true/false title:true/false, subtitle: true/false, disclaimer: true/false, countdowntimer: true/false}',
          // // ?
          // widget_layout: '{top_bottom_padding: 60, side_padding: 120}',
          // stretch: 'true/false',
          // pause_between_images: 0,
        }),
      );
    },
    sitePromoUouView(params: PromoUOUParams) {
      const biFunction = Bi.getBiEventFunction('uouView');
      return biLogger.report(biFunction({ ...params }));
    },
    getCtaId(data: IDataItem) {
      return data.offeringType === 'coupon'
        ? data.offeringId
        : data.offeringItemId || data.offeringPageId;
    },
    sitePromoUouCtaClick(params: PromoUOUParams) {
      const biFunction = Bi.getBiEventFunction('uouCtaClick');
      return biLogger.report(
        biFunction({
          ...params,
        }),
      );
    },
  };
};

const btnNameToParentViewMap: Record<string, ParentViewType> = {
  gfppBtns: 'editor',
  widgetDesignBtns: 'widget_design',
  widgetLayoutBtns: 'widget_layout',
  elementsPanelBtns: 'widget_elements',
  stretchBtns: 'stretch',
  managePromosPanelBtns: 'manage_site_promos',
};

export const getParentView = (btnName: ButtonsTypeBi): ParentViewType => {
  const matchedType = Object.keys(btnNameToParentViewMap).find((btnType) =>
    BiConstants[btnType].includes(btnName),
  );
  return matchedType ? btnNameToParentViewMap[matchedType] : 'editor';
};
