import { ICampaignDataItem, Utils } from '@wix/promotions-types';
import { HttpClient } from '@wix/yoshi-flow-editor';
import { getViewerEssentials } from '../../viewer-utils';

export const getCampaign = async (campaignId: string, instance?: string) => {
  const httpClient = new HttpClient();

  instance =
    instance ||
    (getViewerEssentials().flowAPI.controllerConfig.appParams as string);

  const campaign = (await Utils.DataItemApi(instance, httpClient).queryDataItem(
    campaignId,
  )) as ICampaignDataItem;

  return campaign;
};
