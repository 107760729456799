import { ElementCategoryData } from '@wix/platform-editor-sdk';
import { CampaignType } from '@wix/promotions-types';
import {
  AnnouncementRoles,
  SubscribeMultistateIds,
  SubscribeRoles,
} from '../../enums';

export const CATEGORY_IDS = {
  mainSection: 'promotions.panel.elements.title',
};

export const CATEGORIES_DATA: Record<CampaignType, ElementCategoryData[]> = {
  announcements_type: [
    {
      id: CATEGORY_IDS.mainSection,
      title: CATEGORY_IDS.mainSection,
    },
  ],
  limited_offer_type: [
    {
      id: CATEGORY_IDS.mainSection,
      title: CATEGORY_IDS.mainSection,
    },
  ],
  get_subscribers_type: [
    {
      id: CATEGORY_IDS.mainSection,
      title: CATEGORY_IDS.mainSection,
    },
  ],
};

interface PanelElementI {
  label: string;
  role: string;
  multiState?: string;
}

export const PANEL_DATA: Record<CampaignType, PanelElementI[]> = {
  announcements_type: [
    {
      label: 'promotions.panel.elements.heading_text',
      role: AnnouncementRoles.Heading,
    },
    {
      label: 'promotions.panel.elements.smallStripLine_text',
      role: AnnouncementRoles.smallStripLine,
    },
    {
      label: 'promotions.panel.elements.title_text',
      role: AnnouncementRoles.Title,
    },
    {
      label: 'promotions.panel.elements.subtitle_text',
      role: AnnouncementRoles.Subtitle,
    },
    {
      label: 'promotions.panel.elements.disclaimer_text',
      role: AnnouncementRoles.Disclaimer,
    },
    {
      label: 'promotions.panel.elements.image',
      role: AnnouncementRoles.Image,
    },
  ],
  get_subscribers_type: [
    {
      label: 'promotions.panel.elements.title_text',
      role: SubscribeRoles.Title,
      multiState: SubscribeMultistateIds.mainState,
    },
    {
      label: 'promotions.panel.elements.subtitle_text',
      role: SubscribeRoles.Subtitle,
      multiState: SubscribeMultistateIds.mainState,
    },
    {
      label: 'promotions.panel.elements.disclaimer_text',
      role: SubscribeRoles.Disclaimer,
      multiState: SubscribeMultistateIds.mainState,
    },
    {
      label: 'promotions.panel.elements.title_text',
      role: SubscribeRoles.Title2,
      multiState: SubscribeMultistateIds.successState,
    },
    {
      label: 'promotions.panel.elements.subtitle_text',
      role: SubscribeRoles.Subtitle2,
      multiState: SubscribeMultistateIds.successState,
    },
    {
      label: 'promotions.panel.elements.disclaimer_text',
      role: SubscribeRoles.Disclaimer2,
      multiState: SubscribeMultistateIds.successState,
    },
  ],
  limited_offer_type: [],
};
