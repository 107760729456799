import { EXPERIMENTS, ICampaignDataItem, Utils } from '@wix/promotions-types';
import { TemplateType, bi } from '../../bi';
import { widgetElementIds } from '../../enums';
import { ClientApi } from '../../utils';
import { fetchAndUpdateCampaigns } from './fetchAndUpdateCampaigns';
import { updateWidgetElements } from './updateWidgetElements';

export const getViewerWidgetData = async (
  campaignType,
  $w,
  onFailAction,
  $widget,
  flowAPI,
): Promise<ICampaignDataItem> => {
  // Extract widget properties and environment details
  const { compId, campaignId } = $widget.props;
  const { instance } = flowAPI.controllerConfig.appParams;
  const { isViewer } = flowAPI.environment;
  const isManagePromoBM = flowAPI.experiments.enabled(
    EXPERIMENTS.MANAGE_PROMOS_BM,
  );

  let campaign;

  try {
    // Handle non-manage promo cases
    if (!isManagePromoBM) {
      if (!campaignId) {
        await onFailAction('No campaign id');
        return {} as ICampaignDataItem;
      }
      // Fetch campaign by ID
      campaign = (await ClientApi.getCampaign(
        campaignId,
        instance,
      )) as ICampaignDataItem;
    } else {
      const compIdPrefix = compId.split('_')[0];

      // Fetch and filter campaigns for manage promo cases
      const campaigns = (await fetchAndUpdateCampaigns(
        instance,
      )) as ICampaignDataItem[];
      campaign = campaigns.find(
        (cmp) => cmp.data.promoSlot.id === compIdPrefix,
      ) as ICampaignDataItem;

      if (!campaign) {
        await onFailAction('No campaign id');
        return {} as ICampaignDataItem;
      }
    }

    // Extract widget IDs and campaign status
    const widgetIds = widgetElementIds[campaignType];
    const status = campaign.data.campaignStatus;
    const isCampaignValid =
      Utils.isCampaignTimeValid(campaign) && status !== 'incomplete';

    // Initialize BI logger
    const biLogger = bi({ flowAPI });

    if (campaign.id && isCampaignValid) {
      // Update widget elements if the campaign is valid
      console.log('updateData', { campaign, isCampaignValid });
      await updateWidgetElements({ campaign, widgetIds, $w });
    } else {
      await onFailAction(`collapsing ${campaign} ${isCampaignValid}`);
    }

    // Log BI event if in viewer mode
    if (isViewer && campaign.data._id) {
      biLogger.sitePromoUouView({
        promo_id: campaign.data._id,
        template_type: TemplateType.announcement,
        promo_type: campaign.data.offeringType,
        cta_id: biLogger.getCtaId(campaign.data),
      });
    }

    return campaign;
  } catch (err) {
    await onFailAction(`fetchLiveSiteData err ${err}`);
    return {} as ICampaignDataItem;
  }
};
