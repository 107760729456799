let flowApi, translation, cmpType;

export const getViewerEssentials = () => ({
  flowAPI: flowApi,
  t: translation,
  campaignType: cmpType,
});

export const setViewerEssentials = ({ flowAPI, t, campaignType }) => {
  flowApi = flowAPI;
  translation = t;
  cmpType = campaignType;
};
