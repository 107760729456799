import { CampaignType } from '@wix/promotions-types';
import { DBKeys } from './dbKeys';
import { AnnouncementRoles, SubscribeRoles } from './elementRoles';

type Roles = AnnouncementRoles | SubscribeRoles;
interface Data {
  id: Roles;
  dbKey: DBKeys;
  subPath?: string;
  isMandatory?: boolean;
}

export const widgetElementIds: Record<
  CampaignType,
  Partial<Record<any, Data>>
> = {
  announcements_type: {
    [AnnouncementRoles.Heading]: {
      id: AnnouncementRoles.Heading,
      dbKey: DBKeys.Heading,
      subPath: 'mainStateData',
    },
    [AnnouncementRoles.Title]: {
      id: AnnouncementRoles.Title,
      dbKey: DBKeys.Title,
      isMandatory: true,
      subPath: 'mainStateData',
    },
    [AnnouncementRoles.Subtitle]: {
      id: AnnouncementRoles.Subtitle,
      dbKey: DBKeys.Subtitle,
      isMandatory: true,
      subPath: 'mainStateData',
    },
    [AnnouncementRoles.Disclaimer]: {
      id: AnnouncementRoles.Disclaimer,
      dbKey: DBKeys.Disclaimer,
      subPath: 'mainStateData',
    },
  },
  get_subscribers_type: {
    title: {
      id: SubscribeRoles.Title,
      dbKey: DBKeys.Title,
      subPath: 'mainStateData',
      isMandatory: true,
    },
    subtitle: {
      id: SubscribeRoles.Subtitle,
      dbKey: DBKeys.Subtitle,
      subPath: 'mainStateData',
      isMandatory: true,
    },
    disclaimer: {
      id: SubscribeRoles.Disclaimer,
      dbKey: DBKeys.Disclaimer,
      subPath: 'mainStateData',
    },
    title2: {
      id: SubscribeRoles.Title2,
      dbKey: DBKeys.Title,
      subPath: 'successStateData',
      isMandatory: true,
    },
    subtitle2: {
      id: SubscribeRoles.Subtitle2,
      dbKey: DBKeys.Subtitle,
      subPath: 'successStateData',
      isMandatory: true,
    },
    disclaimer2: {
      id: SubscribeRoles.Disclaimer2,
      dbKey: DBKeys.Subtitle,
      subPath: 'successStateData',
    },
  },
  limited_offer_type: {},
};
