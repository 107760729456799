import { ICampaignDataItem } from '@wix/promotions-types';
import { ClientApi } from '../../utils';

export const fetchAndUpdateCampaigns = async (
  instance,
): Promise<ICampaignDataItem[]> => {
  try {
    const campaignsData = ClientApi.getCampaigns({
      instance,
      shouldUpdate: true,
    });
    return campaignsData;
  } catch (error) {
    console.error('fetchEditorData Error fetching initial data:', error);
    return [];
  }
};
