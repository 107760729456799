import _ from 'lodash';
import { ICampaignDataItem, Utils } from '@wix/promotions-types';
import { ClientApi } from '..';

export const updateStatusByDate = async (
  campaign: ICampaignDataItem,
  instance: string,
): Promise<void> => {
  const campaignId = campaign.id;
  const clonedCampaign = _.cloneDeep(campaign);
  const { campaignStatus } = clonedCampaign.data;
  const statusByTime = Utils.getStatusByDate(clonedCampaign);

  if (campaignStatus !== statusByTime) {
    clonedCampaign.data.campaignStatus = statusByTime;

    console.log('updateStatusByDate', {
      clonedCampaign,
      campaignStatus,
      statusByTime,
    });

    ClientApi.updateCampaign({
      instance,
      campaignId,
      campaign: clonedCampaign.data,
      context: 'updateStatusByDate',
    });
  }
};
