import { CampaignType } from '@wix/promotions-types';
import {
  AnnouncementsMobilePresetIds,
  AnnouncementsPresetIds,
  SubscribeMobilePresetIds,
  SubscribePresetIds,
} from '../enums';

const createPresetToType = (enumArray: any[], type: CampaignType) => {
  let presetsMap = {};
  enumArray.forEach((presetEnum) => {
    const presetObject = Object.values(presetEnum as Object).reduce(
      (acc, presetId) => ({ ...acc, [presetId]: type }),
      {}
    );
    presetsMap = { ...presetsMap, ...presetObject };
  });

  return presetsMap;
};

export const presetToType = {
  ...createPresetToType(
    [SubscribePresetIds, SubscribeMobilePresetIds],
    'get_subscribers_type'
  ),
  ...createPresetToType(
    [AnnouncementsPresetIds, AnnouncementsMobilePresetIds],
    'announcements_type'
  ),
};
