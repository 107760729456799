import {
  SubscribeAssets,
  AnnouncementStudioAssets,
  AnnouncementClassicAssets,
} from '../assets/presets';
import { WidgetIds } from '../constants';
import {
  AnnouncementPresetNames,
  AnnouncementsPresetIds,
  AnnouncementsMobilePresetIds,
  SubscribePresetIds,
  SubscribePresetNames,
  SubscribeMobilePresetIds,
} from '../enums';
import { Presets } from '../types';

const layout = { width: '240px' };

export const announcementsPresets = (isClassic = true): any[] => {
  const presetsThumbnails = isClassic
    ? AnnouncementClassicAssets
    : AnnouncementStudioAssets;
  return [
    {
      name: AnnouncementPresetNames.sideBySide,
      id: AnnouncementsPresetIds.sideBySide,
      connectedMobilePresetID: AnnouncementsMobilePresetIds.sideBySide,
      layout,
      src: presetsThumbnails.sideBySide,
    },
    {
      name: AnnouncementPresetNames.smallStrip,
      id: AnnouncementsPresetIds.smallStrip,
      connectedMobilePresetID: AnnouncementsMobilePresetIds.smallStrip,
      layout,
      src: presetsThumbnails.smallStrip,
    },
    {
      name: AnnouncementPresetNames.centerOpacity,
      id: AnnouncementsPresetIds.centerOpacity,
      connectedMobilePresetID: AnnouncementsMobilePresetIds.centerOpacity,
      layout,
      src: presetsThumbnails.centerOpacity,
    },
    {
      name: AnnouncementPresetNames.midStrip,
      id: AnnouncementsPresetIds.midStrip,
      connectedMobilePresetID: AnnouncementsMobilePresetIds.midStrip,
      layout,
      src: presetsThumbnails.midStrip,
    },
    {
      name: AnnouncementPresetNames.overlay,
      id: AnnouncementsPresetIds.overlay,
      connectedMobilePresetID: AnnouncementsMobilePresetIds.overlay,
      layout,
      src: presetsThumbnails.overlay,
    },
    {
      name: AnnouncementPresetNames.sideBox,
      id: AnnouncementsPresetIds.sideBox,
      connectedMobilePresetID: AnnouncementsMobilePresetIds.sideBox,
      layout,
      src: presetsThumbnails.sideBox,
    },
    {
      name: AnnouncementPresetNames.noImage,
      id: AnnouncementsPresetIds.noImage,
      connectedMobilePresetID: AnnouncementsMobilePresetIds.noImage,
      layout,
      src: presetsThumbnails.noImage,
    },
  ];
};
export const subscribePresets: any[] = [
  {
    name: SubscribePresetNames.sideBySide,
    id: SubscribePresetIds.sideBySide,
    connectedMobilePresetID: SubscribeMobilePresetIds.sideBySide,
    layout,
    src: SubscribeAssets.sideBySide,
  },
  {
    name: SubscribePresetNames.smallStrip,
    id: SubscribePresetIds.smallStrip,
    connectedMobilePresetID: SubscribeMobilePresetIds.smallStrip,
    layout,
    src: SubscribeAssets.smallStrip,
  },
  {
    name: SubscribePresetNames.centerOpacity,
    id: SubscribePresetIds.centerOpacity,
    connectedMobilePresetID: SubscribeMobilePresetIds.centerOpacity,
    layout,
    src: SubscribeAssets.centerOpacity,
  },
  {
    name: SubscribePresetNames.midStrip,
    id: SubscribePresetIds.midStrip,
    connectedMobilePresetID: SubscribeMobilePresetIds.midStrip,
    layout,
    src: SubscribeAssets.midStrip,
  },
  {
    name: SubscribePresetNames.overlay,
    id: SubscribePresetIds.overlay,
    connectedMobilePresetID: SubscribeMobilePresetIds.overlay,
    layout,
    src: SubscribeAssets.overlay,
  },
  {
    name: SubscribePresetNames.sideBox,
    id: SubscribePresetIds.sideBox,
    connectedMobilePresetID: SubscribeMobilePresetIds.sideBox,
    layout,
    src: SubscribeAssets.sideBox,
  },
  {
    name: SubscribePresetNames.noImage,
    id: SubscribePresetIds.noImage,
    connectedMobilePresetID: SubscribeMobilePresetIds.noImage,
    layout,
    src: SubscribeAssets.noImage,
  },
];

export const presets = (
  isClassic = true,
): Record<keyof typeof WidgetIds, Presets> => {
  return {
    ANNOUNCEMENTS: announcementsPresets(isClassic),
    SUBSCRIBE: subscribePresets,
  };
};
