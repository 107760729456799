import { ICampaignDataItem } from '@wix/promotions-types';
import { createCtaBtn } from './createCtaBtn';
import { populateElements } from './populateElements';
import { selectorFactory } from './selectorFactory';

type IWidgetIds = Record<string, { id: string; dbKey: string; subPath?: any }>;
interface UpdateWidgetelementI {
  campaign: ICampaignDataItem;
  widgetIds: IWidgetIds;
  $w: any;
}

export const updateWidgetElements = async ({
  campaign,
  widgetIds,
  $w,
}: UpdateWidgetelementI): Promise<void> => {
  const { campaignType, offeringType } = campaign?.data;
  const { showWidget, changeToDemoContent } = selectorFactory($w, campaignType);

  if (!offeringType) {
    console.error('>>> No offering type found, nothing to update');
    changeToDemoContent(widgetIds);
    return;
  }

  const offeringData = {
    // @ts-expect-error
    coupon: campaign?.data?.offeringData?.couponMetadata?.couponToEdit,
    automatic_discount: campaign?.data?.offeringData,
  };

  const isCoupon = offeringType.includes('coupon');

  const offering = offeringData[offeringType];
  await createCtaBtn({ campaign, $w });

  if (campaignType === 'get_subscribers_type') {
    if (!isCoupon) {
      await $w('#CouponCode').collapse();
    }
  }

  await populateElements(widgetIds, campaign, offering, $w);

  await showWidget();
};
