import {
  AddComponentHandler,
  CollapsedExpandedComponentRef,
  ComponentRef,
  ElementCategoryData,
  ElementData,
  EventType,
  PageRef,
  PopupData,
  PresetValue,
  RemoveComponentHandler,
  ViewportType,
} from '@wix/platform-editor-sdk';

import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  AddComponentOptions,
  AddLightboxOptions,
  AddNewPageOptions,
} from '../types';

interface OpenElementsPanel {
  widgetRef: ComponentRef;
  helpId?: string;
  elementsData: ElementData[];
  categoriesData?: ElementCategoryData[];
  addComponentHandler: AddComponentHandler;
  removeComponentHandler: RemoveComponentHandler;
}

const TOKEN = 'token';
export class EditorSDKUtils {
  private editorSDK: FlowEditorSDK;

  constructor(editorSDK: FlowEditorSDK) {
    this.editorSDK = editorSDK;
  }

  async reloadManifest() {
    return this.editorSDK.document.application.reloadManifest();
  }

  async getCurrentPage() {
    return this.editorSDK.document.pages.getCurrent(TOKEN);
  }

  async addWidget(options) {
    return this.editorSDK.application.appStudioWidgets.addWidget(
      TOKEN,
      options,
    );
  }

  async addLightbox(options: AddLightboxOptions) {
    return this.editorSDK.document.pages.popupPages.add(TOKEN, options);
  }

  async addPage() {
    return this.editorSDK.document.pages.add(TOKEN, {
      title: 'Promotion Page 2',

      definition: {
        id: 'id',
        type: 'Page',
        // componentType: 'wysiwyg.viewer.components.PopupContainer',
        data: {
          managingAppDefId: '7fdf5576-9d52-4c0a-9840-6e226f2ec415',
          isPopup: true,
        },
        // components: [{ componentType: 'wysiwyg.viewer.components.PopupContainer' }]
      },
      shouldAddMenuItem: false,
      shouldNavigateToPage: true,
    });
  }

  async addLightboxComponent(options: any) {
    return this.editorSDK.document.pages.popupPages.addConnected(TOKEN, {
      ...options,
      title: 'Promotions Component',
      controllerType: 'wysiwyg.viewer.components.PopupContainer',
      definition: { data: { isPopup: true } },
      popupRole: 'Promotions_V2_ROLE',
      managingAppDefId: '7fdf5576-9d52-4c0a-9840-6e226f2ec415',
    });
  }

  async addComponent(options: AddComponentOptions) {
    return this.editorSDK.components.add(TOKEN, options);
  }

  async addNewPage(options: AddNewPageOptions) {
    return this.editorSDK.pages.add(TOKEN, options);
  }

  async setFullWidth(widgetRef: ComponentRef) {
    await this.editorSDK.document.components.setFullWidth(TOKEN, {
      componentRef: widgetRef,
      fullWidth: true,
    });
  }

  async addWidgetGfppClickListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.widgetGfppClicked,
      callback,
    );
  }

  async onAddWidgetListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.anyComponentAddedToStage,
      callback,
    );
  }

  async onDeleteWidgetListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.componentDeleted,
      callback,
    );
  }

  async sitePublishedListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.siteWasPublished,
      callback,
    );
  }

  async siteSavedListener(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(EventType.siteWasSaved, callback);
  }

  async getMetaSiteId(): Promise<string> {
    const msid = await this.editorSDK.info.getMetaSiteId(TOKEN);
    return msid;
  }

  async addGlobalDesignPresetChanged(callback: (event: any) => void) {
    return this.editorSDK.addEventListener(
      EventType.globalDesignPresetChanged,
      callback,
    );
  }

  async removeAllOverrides(
    componentRef: ComponentRef,
  ): Promise<ComponentRef[]> {
    // @ts-expect-error
    return this.editorSDK.components.refComponents.removeAllOverrides(TOKEN, {
      componentRef,
    });
  }

  async openComponentPanel({
    componentRef,
    title,
    url,
    height = 198,
    width = 288,
    initialData = {},
    helpId = '',
  }: {
    componentRef: ComponentRef;
    title: string;
    url: string;
    height?: number;
    width?: number;
    initialData?: Record<string, any>;
    helpId: string;
  }) {
    return this.editorSDK.editor.openComponentPanel(
      TOKEN,
      {
        title,
        url,
        height,
        width,
        componentRef,
        initialData: {
          componentRef,
          ...initialData,
        },
        helpId,
      },
      (token) => {
        this.editorSDK.editor.showPanelPreloader(token);
      },
    );
  }

  async changePreset(
    componentRef: ComponentRef,
    viewport: ViewportType,
    id: string,
  ): Promise<void> {
    return this.editorSDK.application.appStudioWidgets.changePreset(TOKEN, {
      context: { viewport },
      componentRef,
      stylePresetId: id,
      layoutPresetId: id,
    });
  }

  async getPreset(componentRef: ComponentRef): Promise<PresetValue> {
    const preset =
      await this.editorSDK.document.application.appStudioWidgets.getPreset(
        TOKEN,
        { componentRef },
      );
    return preset;
  }

  async getAncestors(componentRef: ComponentRef): Promise<ComponentRef[]> {
    return this.editorSDK.components.getAncestors(TOKEN, {
      componentRef,
    });
  }

  async getAllAppRefComponents() {
    return this.editorSDK.components.refComponents.getAllAppRefComponents(
      TOKEN,
    );
  }

  async getCollapsedRefComponents(
    componentRef: ComponentRef,
    includeInnerCollapsed: boolean,
  ): Promise<CollapsedExpandedComponentRef[]> {
    return this.editorSDK.components.refComponents.getCollapsedRefComponents(
      TOKEN,
      {
        componentRef,
        // @ts-expect-error temp until types are GAed
        includeInnerCollapsed,
      },
    );
  }

  async getChildren(componentRef: ComponentRef): Promise<any> {
    const children = await this.editorSDK.components.getChildren(TOKEN, {
      componentRef,
    });
    return children;
  }

  async getType(componentRef: ComponentRef): Promise<string> {
    return this.editorSDK.components.getType(TOKEN, {
      componentRef,
    });
  }

  async expandReferredComponent(componentRef: ComponentRef): Promise<boolean> {
    return this.editorSDK.components.refComponents.expandReferredComponent(
      TOKEN,
      {
        componentRef,
      },
    );
  }

  async refreshLivePreview(
    shouldFetchData: boolean,
    source: string,
  ): Promise<any> {
    return this.editorSDK.application.livePreview.refresh(TOKEN, {
      shouldFetchData,
      source,
    });
  }

  async collapseReferredComponent(componentRef: ComponentRef): Promise<void> {
    this.editorSDK.components.refComponents.collapseReferredComponent(TOKEN, {
      componentRef,
    });
  }

  async findAllByRole(controllerRef: ComponentRef, role: string): Promise<any> {
    return this.editorSDK.document.components.findAllByRole(TOKEN, {
      controllerRef,
      role,
    });
  }

  async getMultistateBoxRef(): Promise<ComponentRef> {
    const componentType = 'wixui.MultiStateBox';
    const [componentRef] = await this.editorSDK.components.findAllByType(
      TOKEN,
      {
        componentType,
      },
    );
    if (!componentRef) {
      throw new Error('Could not find MultiStateBox component');
    }
    return componentRef;
  }

  async getComponentData(componentRef: ComponentRef): Promise<object> {
    return this.editorSDK.components.data.get(TOKEN, { componentRef });
  }

  async getInstance(): Promise<string> {
    return this.editorSDK.document.info.getAppInstance(TOKEN);
  }

  async getInstanceId(): Promise<string> {
    return this.editorSDK.document.info.getAppInstanceId(TOKEN);
  }

  async openPagesPanel(): Promise<void> {
    const { check, show } = this.editorSDK.editor.deeplink;
    const isDeeplink = await check(TOKEN, { type: 'pagesPanel', params: [] });
    if (isDeeplink) {
      await show(TOKEN, { type: 'pagesPanel', params: [] });
    }
  }

  async navigateToLightbox(): Promise<void> {
    await this.editorSDK.document.pages.navigateTo(TOKEN, {
      pageLink: { type: 'PageLink', pageId: 'oy3rh' },
    });
  }

  async getLighboxes(): Promise<PopupData[]> {
    const lightboxes =
      await this.editorSDK.document.pages.popupPages.getApplicationPopups(
        TOKEN,
      );
    return lightboxes;
  }

  // async getLighboxDataList(): Promise<any> {
  //   // const lightboxes = await this.editorSDK.document.pages.popupPages.getDataList(TOKEN);
  //   return lightboxes;
  // }

  async navigateToPopup(popupRef: PageRef): Promise<void> {
    await this.editorSDK.document.pages.popupPages.open(TOKEN, { popupRef });
  }

  async setWidgetProps({
    componentRef,
    newProps,
  }: {
    componentRef: ComponentRef;
    newProps: any;
  }): Promise<void> {
    await this.editorSDK?.document.application.appStudioWidgets.props.set(
      TOKEN,
      {
        widgetRef: componentRef,
        newProps,
      },
    );
  }

  async getWidgetProps(componentRef: ComponentRef): Promise<any> {
    const props = await this.editorSDK?.application.appStudioWidgets.props.get(
      TOKEN,
      { widgetRef: componentRef },
    );
    return props;
  }

  async openDashboard({ url }) {
    await this.editorSDK.editor.openDashboardPanel(TOKEN, {
      url,
      closeOtherPanels: true,
    });
  }

  async getPublicUrl() {
    const publicURL = await this.editorSDK?.document.info.getPublicUrl(TOKEN);
    return publicURL;
  }

  async removeElementFromPanel({ componentRef, role }): Promise<void> {
    try {
      const [controllerRef] = await this.getChildren(componentRef);
      const [elementRef] = await this.findAllByRole(controllerRef, role);
      await this.collapseReferredComponent(elementRef);
    } catch (e) {
      console.error('removeElementFromPanel', { e });
    }
  }

  async openWidgetMenu(componentRef, isLabelHidden = false): Promise<any> {
    this.editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
      TOKEN,
      'settings',
      {
        componentRef,
        configuration: {
          controls: {
            link: { hidden: true },
            label: { hidden: isLabelHidden },
            icon: {},
          },
          controlGroups: {},
          states: {},
        },
      },
    );
  }

  async updateComponent(componentRef, data): Promise<void> {
    await this.editorSDK.components.data.update(TOKEN, {
      componentRef,
      data,
    });
  }

  async getCompStructure(componentRefs): Promise<any> {
    const properties = [
      'data',
      'props',
      'componentType',
      'sdkType',
      'connections',
      'skin',
      'style',
      'role',
    ];
    const compStructure = await this.editorSDK.components.get(TOKEN, {
      componentRefs,
      properties,
    });
    return compStructure;
  }

  async openElementsPanel({
    widgetRef,
    elementsData,
    categoriesData,
    addComponentHandler,
    removeComponentHandler,
  }: OpenElementsPanel): Promise<void> {
    this.editorSDK.editor.openElementsPanel(TOKEN, {
      widgetRef,
      categoriesData: [],
      elementsData,
      addComponentHandler,
      removeComponentHandler,
    });
  }
}
