export const isHTMLStringEmpty = (htmlString: string) =>
  !htmlString.replace(/<[^>]*>/g, '').trim();

export const addUnderlineToLink = (htmlString: string) =>
  htmlString.replace(/<a/gi, '<a style="text-decoration: underline;"');

export const validateEmail = (email) => {
  if (email.trim().length === 0) {
    return false;
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
