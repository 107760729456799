const elementsPanelBtns = [
  'image_on',
  'image_off',
  'heading_on',
  'heading_off',
  'title_on',
  'title_off',
  'subtitle_on',
  'subtitle_off',
  'disclaimer_on',
  'disclaimer_off',
  'timer_on',
  'timer_off',
] as const;

const gfppBtns = [
  'manage_site_promos',
  'widget_settings',
  'widget_elements',
  'widget_layout',
  'widget_design',
  'widget_animation',
  'stretch',
  'help',
] as const;

const stretchBtns = [
  'pause_slider_interaction',
  'stretch_toggle_on',
  'stretch_toggle_off',
] as const;

const managePromosPanelBtns = [
  'create_promo',
  'learn_more',
  'select_promo',
  'select_promo_done',
] as const;

const widgetDesignBtns = ['customize_design', 'select_preset'] as const;

const widgetLayoutBtns = [
  'top_bottom_padding_slider_interaction',
  // 'top_bottom_padding_interaction',
  'side_padding_slider_interaction',
  // 'side_padding_interaction',
] as const;

export {
  elementsPanelBtns,
  gfppBtns,
  stretchBtns,
  managePromosPanelBtns,
  widgetDesignBtns,
  widgetLayoutBtns,
};
