import { ICampaignDataItem, OfferingType } from '@wix/promotions-types';

export const getCamapaignOfferingType = (
  campaign: ICampaignDataItem
): OfferingType | undefined => {
  const { offeringType, offeringId } = campaign?.data;

  if (!offeringId && offeringType !== 'membership') {
    return;
  }

  return offeringType;
};
