import { ICouponOrDiscount, OfferingType, Utils } from '@wix/promotions-types';
import { replaceDynamicValues } from '../../utils';
import { selectorFactory } from './selectorFactory';

const discountTypesMap: Partial<Record<OfferingType, string>> = {
  coupon: 'coupons',
  automatic_discount: 'discounts',
};

const getDiscountByOfferingType = (offeringType: OfferingType, offering) => {
  let discount;
  switch (offeringType) {
    case 'coupon':
      discount = Utils.mapDataToCommonFormat[
        discountTypesMap[offeringType] as OfferingType
      ](offering) as ICouponOrDiscount;
      break;
    case 'automatic_discount':
      discount = offering as ICouponOrDiscount;
      break;
    default:
  }

  return discount;
};

export const populateElements = (widgetIds, campaign, offering, $w) => {
  const { campaignType, offeringType } = campaign?.data;
  const { changeText, formatDisclaimer } = selectorFactory($w, campaignType);
  const discount = getDiscountByOfferingType(offeringType, offering);

  for (const key in widgetIds) {
    const { id, dbKey, subPath, isMandatory } = widgetIds[key];
    const textValue = subPath
      ? campaign?.data[subPath][dbKey]
      : campaign?.data[dbKey];

    const text = replaceDynamicValues({
      textValue,
      endDate: new Date(Number(campaign?.data.campaignDateEnd)),
      discount,
      isMandatory,
    });

    id !== widgetIds.disclaimer.id
      ? changeText(id, text)
      : formatDisclaimer(text, campaignType);
  }
};
