import { onViewerPageReady } from '../../viewer-utils';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI }) => {
  return {
    pageReady: ($w) =>
      onViewerPageReady({
        flowAPI,
        $w,
        $widget,
        campaignType: 'get_subscribers_type',
      }),
    exports: {},
  };
});
