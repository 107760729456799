import { EXPERIMENTS, ICampaignDataItem, Utils } from '@wix/promotions-types';
import { widgetElementIds } from '../../enums';
import { ClientApi } from '../../utils';
import { fetchAndUpdateCampaigns } from './fetchAndUpdateCampaigns';
import { selectorFactory } from './selectorFactory';
import { updateWidgetElements } from './updateWidgetElements';

export const getEditorWidgetData = async (
  campaignType,
  $w,
  onFailAction,
  $widget,
  flowAPI,
) => {
  // Extract translations and experiment flag
  const { t } = flowAPI.translations;
  const isManagePromoBM = flowAPI.experiments.enabled(
    EXPERIMENTS.MANAGE_PROMOS_BM,
  );

  // Extract widget properties and IDs
  const { campaignId, compId } = $widget.props;
  const widgetIds = widgetElementIds[campaignType];

  // Extract instance and broken widget handler
  const { instance } = flowAPI.controllerConfig.appParams;
  const { showBrokenWidget } = selectorFactory($w, campaignType);

  // Validate campaign ID for non-manage promo cases
  if (!isManagePromoBM && !campaignId) {
    return onFailAction(`No Campaign id Found id:${campaignId}`);
  }

  // Fetch and filter campaigns
  const campaigns = (await fetchAndUpdateCampaigns(
    instance,
  )) as ICampaignDataItem[];
  const campaignsOfType = campaigns.filter(
    (campaign) => campaign.data.campaignType === campaignType,
  );

  // Find the relevant campaign
  let campaign: ICampaignDataItem | undefined;
  if (!isManagePromoBM) {
    campaign = campaigns.find((cmp) => cmp.id === campaignId);
  } else {
    // Extract component ID prefix
    const compIdPrefix = compId.split('_')[0];

    campaign = campaigns.find((cmp) => cmp.data.promoSlot.id === compIdPrefix);

    // Update widget props if campaign ID does not match
    if (campaign?.id !== campaignId) {
      const wixWidget = flowAPI.controllerConfig.wixCodeApi.widget;
      await wixWidget?.setProps({ campaignId: campaign?.id });
    }
  }

  // Handle missing campaign
  if (!campaign) {
    return onFailAction(`No Campaign Found ${campaigns} id:${campaignId}`);
  }

  // Extract campaign details
  const promoName = campaign.data.campaignName || '';
  const isExpired = Utils.getIsExpired(campaign.data.campaignDateEnd);
  const isIncomplete = campaign.data.campaignStatus === 'incomplete';

  // Update campaign status
  ClientApi.updateStatusByDate(campaign, instance);

  // Handle expired, incomplete, or missing campaigns of the correct type
  if (isExpired) {
    console.log('=> Campaign has expired', campaign);
    await showBrokenWidget(promoName, t, 'expired');
  } else if (isIncomplete) {
    console.log('=> Campaign is incomplete', campaign);
    await showBrokenWidget(promoName, t, 'incomplete');
  } else if (!campaignsOfType.length || !campaign) {
    console.log('No campaigns of correct type or campaign deleted', {
      campaignsOfType,
    });
    await onFailAction(`No correct type|campaign deleted ${campaignsOfType}`);
  } else {
    // Update widget elements
    await updateWidgetElements({ campaign, widgetIds, $w });
  }
};
